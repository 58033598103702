.sponsors-container {
  display: grid;
  width: 90vw;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
  transition: all ease-in-out 0.3s;
  margin-left: auto;
  margin-right: auto;
  margin-top: 23vh;
  justify-items: center;
}

.sponsors-item {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 20vw;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
}

.sponsors-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: all ease-in-out 0.3s;
}

.sponsor {
  color: black;
  transition: all ease-in-out 0.3s;
}

.sponsors-item {
  position: relative;
  display: flex;
  border-top: 8px solid #fb0;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
}

.sponsors-item:hover{
  scale: 1.2;
  border-top: none;
}

.sponsors-item:hover::before {
  border-top: none;
  content: attr(data-text);
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  font-size: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fb0;
  z-index: 1;
  scale: 1.2;
  opacity: .9;
  transition: all ease-in-out 0.3s;
}

.sponsors-item:hover img {
  filter: grayscale(100%);
  transition: all ease-in-out 0.3s;
}

.sponsors-item:hover .sponsor-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  transition: all ease-in-out 0.3s;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 700px) {
  .sponsors-container {
    margin-top: 20vh;
    font-size: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .sponsors-item{
    width: 150px;
  }
}

@media (max-width:400px){
  .sponsors-item:hover::before {
    font-size: 10px;
}
}