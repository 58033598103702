.Page {
  user-select: none;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  background-color: #141e30;
}

.bg1 {
  text-align: start;
  position: relative;
  height: 88vh;
  margin-top: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  background: #141e30;
  padding: 0;
  background-image: url("../assets/bg1.png");
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.bg2{
  text-align: start;
  position: relative;
  height: 45vw;
  margin-top: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  background: #141e30;
  padding: 0;
  background-image: url("../assets/bg1.png");
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.divider1 {
  border: 0px;
  border-top: 2px solid #ffbb00;
  width: 100vw;
  height: 0px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}

.divider2 {
  margin-top: 0px;
  border: 0px;
  border-top: 2px solid #fff;
  width: 200px;
  margin-bottom: 40px;
}
.divider3{
  margin-top: 0px;
  border: 0px;
  margin: auto;
  border-top: 2px solid #fff;
  width: 80%;
  margin-bottom: 40px;
}

ul {
  margin: 0;
  padding: 0 0 0 30px;
}

h1 {
  margin-bottom: 0px;
}

h2 {
  margin-bottom: 50px;
}

.align {
  margin-left: 1%;
}

.body {
  padding-left: 10vw;
  line-height: 2;
  padding-right: 10vw;
  font-size: 20px;
}

.mainLogo {
  width: 33.5vw;
  transition: all 1s ease-in-out;
  justify-self: center;
  animation: fadeIn 5s;
}

.container {
  padding: 0;
  margin-top: 8.8vh;
  margin-left: auto;
  margin-right: auto;
  width: 99vw;
  display: grid;
  grid-template-columns: 1fr 4fr;
  transition: all 1s ease-in-out;
}

.content {
  font-weight: 500;
  user-select: text;
  transition: all 0.3s ease-in-out;
}

.content1 {
  font-weight: 500;
  user-select: text;
  transition: all 0.3s ease-in-out;
}

.content1:hover {
  scale: 1;
  transition: all 0.3s ease-in-out;
}

.content:hover {
  scale: 1.03;
  transition: all 0.3s ease-in-out;
}

.banner {
  position: absolute;
  left: 0;
  right: 0;
  margin: 100px auto;
  width: 70%;
  z-index: 3;
  border: #ffbb00 solid 2px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b3b57;
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  padding: 1%;
  padding-top: 1.1%;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.npm {
  margin-bottom: auto;
  padding: 3%;
  padding-left: 1w;
  padding-right: 1vw;
  margin-top: auto;
  font-size: 2vw;
  color: white;
  padding-top: 4%;
}

.Logo-sepi {
  margin-top: 0.3%;
  position: static;
  margin-left: 5px;
  max-width: 10vw;
  margin-left: 1vw;
  height: 4.5vw;
  object-fit: contain;
}

.Logo-ATL {
  position: static;
  margin-top: 0.3%;
  max-width: 4.5vw;
  margin-right: 1vw;
  height: 4.5vw;
  object-fit: contain;
}

.textMain {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  justify-self: center;
}

.text1 {
  padding: 0;
  font-size: 7.7vw;
  color: white;
  margin: 0;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out 0.5s forwards;
  transition: all 1s ease-in-out;
}

.text2 {
  padding: 0;
  padding-left: 10px;
  font-size: 2vw;
  color: white;
  margin: 0;
  opacity: 0;
  animation: fadeIn 1s ease-in-out 1s forwards;
  transition: all 2s ease-in-out;
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100px);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Mdia */

@media (min-width: 1000px) {
  .banner {
    scale: 1.2;
    top: -110px;
  }
}
@media (max-width: 1000px) {
  #mainLogo {
    width: 35vw;
    transition: all 1s ease-in-out;
    margin-left: 25px;
  }

  .bg1 {
    width: 100vw;
    height: 80vh;
    padding: 0;
    padding-top: 55px;
    padding-bottom: 50px;
  }

  .banner {
    scale: 1.2;
    top: -100px;
  }

  .text1 {
    font-size: 40px;
    margin-right: 70px;
  }

  .text2 {
    font-size: 20px;
    margin-right: 20px;
  }

  .container {
    margin-left: 0;
    margin-right: auto;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    width: 99vw;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out;
    margin-left: 13%;
  }

  .content{
    text-align: justify;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 15px;
  }

.bg1{
  height: 20vh;
}

.bg2{
  height: 25vh;
}

  .text1 {
    width: 78vw;
    font-size: 38px;
    margin: 0;
  }

  .p1 {
    font-size: 30px;
  }

  .p2 {
    font-size: 30px;
  }

  .p3 {
    font-size: 30px;
  }

  .p4 {
    font-size: 30px;
  }

  .p5 {
    font-size: 30px;
  }

  .p6 {
    font-size: 30px;
  }

  .span-bold {
    font-weight: 300;
  }

  .text2 {
    width: 78vw;
    margin: 0;
    padding: 0;
  }

  .textMain {
    margin: 0;
    width: 90vw;
  }

  .mainLogo {
    scale: 2.5;
    padding-bottom: 50px;
    margin-left: 22%;
  }



  .divider2{
    margin-top: 30px;
    width: 80%;
    margin-bottom: 5px;
  }
}
