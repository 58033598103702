.header-program {
  height: 20vh;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.title-program {
  width: 100vw;
  text-align: center;
  margin: none;
}

.subtitle-program {
  margin: 0;
  font-size: 2vw;
  justify-self: center;
}

@media (max-width: 450px) {
  .title-program {
    font-size: 12px;
  }

  .subtitle-program {
    font-size: 10px;
  }

  li {
    font-size: 13px;
    text-align: justify;
  }
}
