.footer {
  position: relative;
  margin-top: 2%;
  border-top: 2px solid #ffbb00;
  width: 100vw;
  background-color: #141e30;
  color: #fff;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 20px;
  z-index: 3;
}

.footer-container {
  max-width: 90vw;
  margin: 0 auto;
  display: grid;
  text-align: center;
  grid-template-columns: 2fr 1fr 2fr;
  justify-items: center;
  justify-content: center;
  align-content: center;
}


.footer-container p {
  margin: 0;
  font-size: 14px;
}

.footer-container a {
  color: #fff;
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: none;
  font-weight: 600;
}

.footer-container a:hover {
  text-decoration: underline;
}


@media (max-width:600px) {
  .footer{
    position: relative;
  }

  .footer-container p{
    font-size: 10px;
  }
}