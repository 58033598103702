.NavbarContainer {
  user-select: none;
  background-color: #141e30;
  position: fixed;
  width: 100%;
  height: 30px;
  top: 0;
  color: white;
  display: flex;
  justify-content: center;
  padding: 20px;
  z-index: 10;
  border-bottom: #ffbb00 solid 1px;
  padding-left: 0;
}

.NavbarLink:hover {
  color: #ffbb00;
  transition: all .3s ease-in-out;
}

.NavbarLinkDropdown:hover .NavbarLinkDropdownContent {
  display: block;
  border-radius: 10px;

}

.NavbarLinkDropdownContent a {
  color: white;
  width: 200px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.NavbarLinks {
  display: flex;
  word-spacing: 10%;
  width: 100%;
  justify-self: center;
  justify-content: space-evenly;
}

.NavbarLink {
  margin-top: -3px;
  margin-bottom: 0px;
  padding-top: 0;
  display: flex;
  position: relative;
  color: white;
  text-decoration: none;
  position: relative; 
}

.NavbarLink::after {
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #ffbb00;
  transform: scaleX(0);
  transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.3s ease-in-out;
}

.NavbarLink:hover::after {
  transform: scaleX(1);
}

.NavbarLink:hover::before {
  right: 0;
}


.NavbarLink:hover {
  color: #ffbb00;
  transition: all .3s ease-in-out;
}


a {
  color: white;
  position: relative;
  text-decoration: none;
  font-size: 30px;
  z-index: 2;
  transition-duration: .4s;
}

a:hover {
  color: #ffbb00;
  transition-duration: .4s;
}

.menu-button {
  background-color: transparent;
  display: flex;
  justify-self: right;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 46px;
  outline: none;
  padding: 10px;
  position: absolute;
  top: -10px;
  right: 40px;
  display: none;
}

@media (max-width: 1460px) {
  .NavbarLinks {
    display: none;
  }

  .NavbarContainer {
    justify-content: right;
    position: sticky;
    margin-right: 10px;
  }


  .menu-button {
    display: block;
    box-shadow: none;
  }
}

.menu-button:hover {
  color: #ffbb00;
  background-color: transparent;
  box-shadow: none;
}

.NavbarLogo:hover {
  color: white;
}

.menu-list {
  background-color: rgba(20, 30, 48, .50);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0; 
  border-left: #ffbb00 2px solid;
  transform: translateX(100%);
  transition: all .5s ease-in-out;
  display: none;
  z-index: 4;
}

.NavbarLogo {
  user-select: none;
  font-size: 25px;
  margin-right: 0px;
}

.menu-section {
  border-bottom: #ffbb00 1px solid;
  width: 100%;
  padding: 3.19vh;
  font-size: 30px;
  transition: background-position 0.3s ease-in-out;
}

.menu-section::before {
  content: '';
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 100%;
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding: 0;
  background-color: #ffbb00;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.menu-section:hover::before {
  left: 0;
}


@media (max-width: 1460px) {
  .NavbarContainer {
    width: 100vw;
    justify-content: right;
  }

  .menu-list {
    display: flex;
    width: 35%;
    height: 100%;
    margin-top: 60px;
    flex-direction: column;
    align-items: flex-start;
  }

  .NavbarContainer {
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #ffbb00;
  }

  a {
    color: white;
    position: relative;
    text-decoration: none;
    font-size: 64px;
    z-index: 2;
    transition-duration: .4s;
  }
}

.menu-list.open {
  position: fixed;
  top: 2px;
  background-color: rgba(20, 30, 48, .50);
  backdrop-filter: blur(10px);
  transform: translateX(0);
  z-index: 5;
  transition-duration: .7s;
}

.menu-link {
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: 28px;
}

.menu-link:hover {
  color: white;
}

@media (max-width: 780px) {
  .NavbarContainer {
    width: 100vw;
    justify-content: right;
  }

  .menu-list {
    display: flex;
    width: 60%;
    height: 100%;
    margin-top: 60px;
    flex-direction: column;
    align-items: flex-start;
  }

.menu-section{
  font-size: 20px;
}

  .NavbarContainer {
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 28px;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #ffbb00;
  }

  a {
    color: white;
    position: relative;
    text-decoration: none;
    z-index: 2;
    transition-duration: .4s;
  }
}

.menu-list.open {
  position: fixed;
  top: 2px;
  background-color: rgba(20, 30, 48, .50);
  backdrop-filter: blur(10px);
  transform: translateX(0);
  transition-duration: .7s;
}

.menu-link {
  width: 100%;
  color: white;
  text-decoration: none;
}

.menu-link:hover {
  color: white;
}