.subiecte-page{
    margin-top: 100px;
    margin-left: 1%;
    margin-right: 1%;
}


.radio-selection{
    width: 30vw;
    height: 30px;
}
