.rezultate-h2 {
  width: 100;
  text-align: center;
}

.rezultate {
  user-select: none;
  display: grid;
  align-items: center;
  height: fit-content;
  width: 90vw;
  text-align: center;
  margin: auto;
  margin-bottom: 100px;
  font-size: 30px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 3.2%;
  grid-row-gap: 2%;
}

.premii-botttom {
  display: grid;
  width: 99vw;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: px; 
  grid-row-gap: 0px;
  margin: auto;
  text-align: center;
  margin-top: 5%;
  padding: 10px;
  background-color: #1d273a;
  }

  .table1{
    overflow: scroll;
  }
  @media (max-width:880px) {
    .table1{
        font-size: 6px;
        margin-left: auto;
        margin-right: auto;
        width: 95vw;
    }    

    .premii-botttom{
      font-size: 9px;
    }
    
}

@media (max-width: 1200px) {
  .rezultate {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 225px;
  }
}

.cards {
  background-color: #2b3b57;
  padding: 20px;
  height: 65%;
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
}

.cards:hover {
  background-color: #fb0;
  scale: 1.01;
  transition: all ease-in-out 0.3s;
}

.th-rez {
  text-align: center;
  border: none;
  user-select: none;
}

.th-rez:hover {
  border: none;
}

.button-rez {
  width: 100vw;
  text-align: center;
  display: inline-block;
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  border: none;
  margin-bottom: 60px;
  border-radius: 5px;
}

.button-sub {
  font-size: 15px;
  background-color: transparent;
}

.button-sub:hover {
  color: white;
}

.td-hov {
  text-align: center;
  font-size: 13px;
  border: none;
}

.td-hov:hover {
  background-color: rgb(153, 113, 1);
  color: white;
}
