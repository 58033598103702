html {
  background:#141e30;
  background-size: cover;
  margin: 0px;
  height: 100%;
  padding: 0px;
  
}

body{
  width: 100%;
  height: 100%;
  margin: 0px;
}

.Page {
  background-color: #1a1a1a;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

div{
  margin:0px;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #030a18;
}
::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(97, 97, 97);
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent white transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent white transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: white transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: white transparent transparent transparent;
}

@media (max-width: 1000px) {
    ::-webkit-scrollbar {
        width: 2px;
      }
    }