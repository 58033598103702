.news-content {
  vertical-align: middle;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  user-select: none;
  margin: auto 0;
}

.news-heading {
  color: #243657;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.news-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 7rem;
  width: 90%;
}

.banner2 {
  position: absolute; 
  user-select: none;
  top: -50px;
  left: 0;
  right: 0;
  margin: 95px auto;
  width: 70vw;
  z-index: 3;
  border: #ffbb00 solid 2px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b3b57;
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  padding: 1%;
}

.news-card {
  position: relative;
  overflow: hidden;
  background-color: #2b3b57;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
  z-index: 1  ;
}

.news-card:hover {
  scale: 1.3;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.news-image {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.news-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  transition: all 0.3s ease-out;
}

.page{
  overflow-x: hidden;
}

.news-text {
  padding: 2rem;
}

.news-title {
  color: #243657;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.news-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.news-heading {
  margin-top: 4%;
  margin-bottom: 4%;
  z-index: 2;
  color: #fff;
}

@media only screen and (max-width: 400px) {
  .news-content {
    margin-top: 0%;
    padding: 2.5rem;
  }

  .news-heading {
    margin-bottom: 20%;
    margin-top: 30%;
  }

  .news-card-container{
    width: 100%;
  }

  .news-card:hover{
    scale: 1;
  }

  .banner2 {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 95px auto;
    width: 70vw;
    z-index: 3;
    border: #ffbb00 solid 2px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2b3b57;
    animation-name: slideDown;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    padding: 1%;;
  }

  .banner2{
    scale: 1.2;
    margin-top: 110px;
  }
}
@media only screen and (min-width: 401px) {
  .news-content {
    margin-top: 0%;
    padding: 5rem;
  }

  .news-heading {
    margin-bottom: 20%;
    margin-top: 10%;
  }

}

@media only screen and (min-width: 1460px) {
  .news-content {
    margin-top: 0%;
    padding: 5rem;
    padding-top: 8.5%;
    padding-bottom: 10.01%;
  }
  .news-heading {
    margin-bottom: 5%;
    margin-top: 10%
  }
}
