.news-card {
    display: flex;
    flex-direction: column;
    background-color: #2b3b57;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .news-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .news-text {
    padding: 16px;
  }
  
  .news-title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #fb0;
  }
  
  .news-description {
    margin: 0;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
  }
  