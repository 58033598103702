.contact-page {
  margin-top: 10%;
  height: 64vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

h1 {
  font-size: 48px;
  margin-bottom: 30px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
}

label {
  font-size: 20px;
  margin-bottom: 10px;
}

input,
textarea {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: 100%;
}

button {
  font-size: 16px;
  background-color: #fb0;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: rgb(197, 145, 0);
}
